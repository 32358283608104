<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <div class="div-logo">
      <b-link
        class="logo"
      >
        <b-img
          fluid
          :src="logo404"
          alt="Logo - Optimum Equipo"
        />
      </b-link>
    </div>

    <div class="misc-inner p-2 p-sm-3">
      <div class="card">
        <div class="card-body">
          <div class="w-100 text-center">
            <span class="ml-auto mr-auto search">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                class="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>

            </span>
            <h4 class="mb-1 mt-2">
              Parece que el recurso o pagina que buscas no existe
            </h4>
            <p class="mb-2">
              Si el problema persiste contacta a tu supervisor
            </p>
          </div>
        </div>
        <div class="card-footer text-center">
          <b-button
            variant="primary"
            class="btn-sm-block"
            :to="{ name: 'dashboard' }"
          >
            Regresar a Inicio
          </b-button>
        </div>
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import store from '@/store/index'

export default {
  components: {
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      logo404: require('@/assets/images/pages/logo-404.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
.brand-logo{
  left:none !important;
}
.btn-primary{
  background-color: #13273E !important;
  border-color: #13273E !important;
  box-shadow: none !important;
}
.misc-wrapper .misc-inner{
  width: 700px !important;
}
.card{
  border: 1px solid #D0D4D8 !important;
  border-radius: 0.628rem !important;
  box-shadow: 0px 5px 5px 0px rgba(100,100,100,0.41) !important;
  color: #000000 !important;
}
h4 {
  font-weight: bold;
  color: #000000 !important;
}
.card-body{
  padding: 2.5rem;
}
.search{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: block;
  background-color: #EBEBEB;
  padding: 25px;
  color: #000000;
}
.logo{
  display: flex;
}
body {
  background-color: #F6F6F6!important;
}
.div-logo{
  position: absolute !important;
  top: 0rem !important;
  height: 70px !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  background-color: #fff !important;
}
</style>
